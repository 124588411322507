import React from 'react'

import { default as LoadingSpinner } from 'src/components/Library/Loading'
import NpsMeasurementView from 'src/components/Nps/NpsSettings/NpsMeasurementView/NpsMeasurementView'

export const QUERY = gql`
  query FindNpsMeasurementQuery($id: Int!) {
    npsMeasurement(id: $id) {
      id
      name
    }
    npsAssessors {
      id
      name
    }
    npsSubjects: npsSubjectsByClient {
      id
      name
      membership {
        id
        user {
          name
        }
      }
    }
    memberships: membershipsByClient {
      id
      user {
        name
      }
    }
    membershipGroups: membershipGroupsByClient {
      id
      name
      parentGroup {
        id
        name
      }
      childGroup {
        id
        name
      }
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = () => <div>Empty</div>

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success = ({
  npsMeasurement,
  memberships,
  membershipGroups,
  npsAssessors,
  npsSubjects,
}) => {
  const alreadyCreatedSubjectMembers = npsSubjects.map((subject) => {
    if (subject.membership) {
      return subject.membership.id
    } else {
      return false
    }
  })

  const membershipsFiltered = memberships?.filter((membership) => {
    if (!alreadyCreatedSubjectMembers.includes(membership.id)) {
      return membership
    } else {
      return false
    }
  })

  const filteredNpsSubjects = membershipsFiltered?.map((membership) => {
    return {
      memberId: membership.id,
      name: membership.user.name,
      __typename: membership.__typename,
    }
  })

  const newNPSSubjects = [...filteredNpsSubjects, ...npsSubjects]

  return (
    <>
      <NpsMeasurementView
        measurement={npsMeasurement}
        memberships={memberships}
        membershipGroups={membershipGroups}
        assessors={npsAssessors}
        subjects={newNPSSubjects}
      />
    </>
  )
}
