import React, { FC } from 'react'

import {
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid'
import { Membership, NpsSubject } from 'types/graphql'
import { useBoolean } from 'usehooks-ts'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Button from 'src/components/Library/Button/Button'
import Divider from 'src/components/Library/Divider/Divider'
import SimpleForm from 'src/components/Library/SimpleForm/SimpleForm'
import Modal from 'src/components/Modal/Modal'
import { useConfirm } from 'src/lib/hooks/Confirmation/index'
import {
  CREATE_NPS_SUBJECT,
  DELETE_NPS_SUBJECT,
  UPDATE_NPS_SUBJECT,
} from 'src/lib/queries/Settings/NPS/NpsSubject'

interface NPSSubjectsViewProps {
  npsSubjects: NpsSubject[]
  members: Membership[]
}

const NpsSubjectsView: FC<NPSSubjectsViewProps> = ({
  npsSubjects,
  members,
}) => {
  const editSubject = useBoolean(false)
  const addSubject = useBoolean(false)
  const confirm = useConfirm()
  const [myMap, setMyMap] = React.useState(
    new Map([
      ['Id', ''],
      ['Name', ''],
      ['Position', ''],
    ]),
  )
  const updateMap = (key, value) => {
    setMyMap(myMap.set(key, value))
  }

  const [updateNpsSubject] = useMutation(UPDATE_NPS_SUBJECT, {
    onCompleted: () => {
      toast.success('Performers Updated', {
        duration: 2000,
        className: 'flex-column',
      })
    },
    refetchQueries: ['NpsSubjectsQuery'],
    onError: (error) => {
      toast.error(error.message, {
        duration: 5000,
        className: 'flex-column',
      })
    },
  })

  const [createNpsSubject] = useMutation(CREATE_NPS_SUBJECT, {
    onCompleted: () => {
      toast.success('Subject Added', {
        duration: 2000,
        className: 'flex-column',
      })
    },
    refetchQueries: ['NpsSubjectsQuery'],

    onError: (error) => {
      toast.error(error.message, {
        duration: 5000,
        className: 'flex-column',
      })
    },
  })

  const [deleteNpsSubject] = useMutation(DELETE_NPS_SUBJECT, {
    onCompleted: () => {
      toast.success('Subject Deleted', {
        duration: 2000,
        className: 'flex-column',
      })
    },
    refetchQueries: ['NpsSubjectsQuery'],
    onError: (error) => {
      toast.error(error.message, {
        duration: 5000,
        className: 'flex-column',
      })
    },
  })

  const handleUpdate = (createNew: boolean) => {
    if (createNew) {
      createNpsSubject({
        variables: {
          input: {
            name: myMap.get('Name'),
            position: myMap.get('Position'),
          },
        },
      }).then(() => {
        editSubject.setFalse()
        addSubject.setFalse()
      })
    } else {
      updateNpsSubject({
        variables: {
          id: myMap.get('Id'),
          input: {
            name: myMap.get('Name'),
            position: myMap.get('Position'),
          },
        },
      }).then(() => {
        editSubject.setFalse()
        addSubject.setFalse()
      })
    }
  }

  const onDelete = (id, name) => {
    return confirm({
      title: `Delete ${name}?`,
      description: 'Are you sure you want to delete this Subject?',
    }).then(() => {
      return deleteNpsSubject({ variables: { id } })
    })
  }

  const CustomFooter = () => (
    <div className={'bg-gray-50 mt-2'}>
      <Button
        variant="outlined"
        className="bg-none"
        startIcon={<PlusIcon className={'stroke-2 h-5 w-5'} />}
        onClick={() => {
          setMyMap(
            new Map([
              ['Name', ''],
              ['Position', ''],
            ]),
          )
          editSubject.setTrue()
          addSubject.setTrue()
        }}
      >
        Add Performer
      </Button>
    </div>
  )

  const headerStyle =
    'bg-gray-50 text-gray-500 text-xs font-medium tracking-wider leading-3 uppercase'

  const memberColumns: GridColDef[] = [
    {
      field: 'user',
      headerName: 'Hubs Users',
      headerClassName: headerStyle,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <>
          <Avatar
            alt={params.row.user.name}
            src={params.row.user.avatarUrl}
            sx={{ width: 40, height: 40, marginLeft: 2, marginRight: 10 }}
          />
          {params.row.user.name}
        </>
      ),
    },
    {
      field: 'position',
      headerName: 'Position',
      headerClassName: headerStyle,
      flex: 1,
      minWidth: 150,
      valueGetter: (_value, row) => {
        return `${row.user.position || ''}`
      },
    },
  ]

  const customMemberColumns: GridColDef[] = [
    {
      field: 'user',
      headerName: 'Custom Performer',
      headerClassName: headerStyle,
      flex: 1,
      minWidth: 150,
      valueGetter: (_value, row) => {
        return `${row.name || ''}`
      },
    },
    {
      field: 'position',
      headerName: 'Position',
      headerClassName: headerStyle,
      flex: 1,
      minWidth: 150,
      valueGetter: (_value, row) => {
        return `${row.position || ''}`
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerClassName: headerStyle,
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          key={1}
          icon={<PencilSquareIcon className={'w-4 h-4 stroke-2'} />}
          label="Edit"
          onClick={() => {
            editSubject.setTrue()
            setMyMap(
              new Map([
                ['Id', params.row.id],
                ['Name', params.row.name],
                ['Position', params.row.position],
              ]),
            )
          }}
        />,
        <GridActionsCellItem
          key={2}
          icon={<TrashIcon className={'w-4 h-4 stroke-2'} />}
          label="Delete"
          onClick={() => {
            onDelete(params.row.id, params.row.name)
          }}
        />,
      ],
    },
  ]

  return (
    <div className={'flex flex-col w-1/2'}>
      <h1 className={'text-base leading-7 text-gray-800'}>NPS Performers</h1>
      <Divider className={'p-0 -ml-1'} />
      <div className={'w-full h-full rounded my-4 bg-gray-50 p-4'}>
        <div className={'h-1/2 w-full bg-white'}>
          <DataGrid
            columns={memberColumns}
            rows={members}
            hideFooter
            rowHeight={50}
            columnHeaderHeight={25}
            disableColumnMenu
            disableColumnSelector
            disableRowSelectionOnClick
            sx={{
              flex: 1,
            }}
          />
        </div>
        <div className={'h-2/5 bg-white mt-8'}>
          <DataGrid
            columns={customMemberColumns}
            rows={npsSubjects.filter((subject) => subject.name !== null) || []}
            rowHeight={30}
            columnHeaderHeight={25}
            disableColumnSelector
            disableRowSelectionOnClick
            hideFooterPagination
            slots={{ footer: CustomFooter }}
            sx={{
              flex: 1,
            }}
          />
        </div>
      </div>
      <Modal
        onClose={() => {
          editSubject.setFalse()
          addSubject.setFalse()
        }}
        open={editSubject.value}
        title={addSubject.value ? 'Add Performer' : 'Edit Performer'}
        dialogClassName={'!p-0 max-w-[720px]'}
      >
        <div className={'p-4'}>
          {editSubject.value && (
            <SimpleForm records={myMap} handleChange={updateMap} />
          )}
        </div>
        <div className="flex flex-row w-full justify-end p-2 bg-gray-100 border border-t-gray-300">
          <Stack direction={'row'} spacing={2}>
            <Button
              variant={'text'}
              onClick={() => {
                editSubject.setFalse()
                addSubject.setFalse()
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                const nameInvalid = myMap.get('Name') === ''
                if (nameInvalid) {
                  toast.error('Performer name is required')
                  return
                }

                handleUpdate(addSubject.value)
              }}
            >
              Save
            </Button>
          </Stack>
        </div>
      </Modal>
    </div>
  )
}

export default NpsSubjectsView
