import { FC } from 'react'

import { NPSAssessorsQuery, NPSAssessorsQueryVariables } from 'types/graphql'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'
import NPSAssessorsView from 'src/components/Nps/NpsSettings/NPSAssessorsView/NPSAssessorsView'

export const QUERY = gql`
  query NPSAssessorsQuery {
    assessors: npsAssessors {
      id
      name
    }
  }
`

export const Loading = () => <LoadingSpinner />
type Props = CellSuccessProps<NPSAssessorsQuery, NPSAssessorsQueryVariables>
export type NPSAssessors = Props['assessors']
export type NPSRefetch = Props['queryResult']['refetch']
export const Empty: FC<Props> = ({ assessors, queryResult: { refetch } }) => (
  <NPSAssessorsView assessors={assessors} refetch={refetch} />
)

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success: FC<Props> = ({ assessors, queryResult: { refetch } }) => {
  return <NPSAssessorsView assessors={assessors} refetch={refetch} />
}
