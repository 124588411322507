import React, { useEffect, useState } from 'react'

import { ArrowSmallLeftIcon } from '@heroicons/react/24/solid'
import TextField from '@mui/material/TextField'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import {
  Membership,
  MembershipGroup,
  NpsAssessor,
  NpsMeasurement,
} from 'types/graphql'
import { useBoolean } from 'usehooks-ts'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Button from 'src/components/Library/Button/Button'
import Divider from 'src/components/Library/Divider/Divider'
import SimpleForm from 'src/components/Library/SimpleForm/SimpleForm'
import Modal from 'src/components/Modal/Modal'
import NpsCampaignCell from 'src/components/Nps/NpsSettings/NpsCampaignCell'
import NpsSettingsView from 'src/components/Nps/NpsSettings/NpsMeasurementView/Views/NpsSettingsView'
import { CREATE_NPS_CAMPAIGN } from 'src/lib/queries/Settings/NPS/NpsCampaign'

interface Subject {
  id: string
  name: string
}

interface NpsMeasurementViewProps {
  measurement: NpsMeasurement
  membershipGroups: MembershipGroup[]
  memberships: Membership[]
  assessors: NpsAssessor[]
  subjects: Subject[]
}

enum MeasurmentViews {
  SETTINGS = 'Settings',
  CAMPAIGNS = 'Campaigns',
}

const NavigationHeader = ({ activeView, setActiveView, createNewCampaign }) => {
  return (
    <>
      <div className="flex justify-between">
        <div className="ml-4 self-center">
          <h2 className="text-base leading-6 font-medium">{activeView}</h2>
        </div>
        <div className="flex">
          {
            {
              [MeasurmentViews.CAMPAIGNS]: (
                <>
                  <Button
                    fullWidth={false}
                    onClick={createNewCampaign}
                    buttonDataTestId="create-campaign-button"
                  >
                    Create Campaign
                  </Button>
                </>
              ),
              [MeasurmentViews.SETTINGS]: (
                <>
                  <Button
                    fullWidth={false}
                    className={'mx-2'}
                    startIcon={
                      <ArrowSmallLeftIcon className="w-4 h-4 stroke-2" />
                    }
                    onClick={() => setActiveView(MeasurmentViews.CAMPAIGNS)}
                  >
                    Back
                  </Button>
                </>
              ),
            }[activeView]
          }
        </div>
      </div>
      <Divider className={'mt-2 p-0'} />
    </>
  )
}

const NpsMeasurementView: React.FC<NpsMeasurementViewProps> = ({
  measurement,
  memberships,
  membershipGroups,
  assessors,
  subjects,
}) => {
  const [activeView, setActiveView] = useState(MeasurmentViews.CAMPAIGNS)
  const campaignModal = useBoolean(false)
  const [campaignForm, setCampaignForm] = useState(new Map())
  const [selectedDate, setSelectedDate] = useState(dayjs())
  const updateCampaignForm = (key, value) => {
    setCampaignForm(campaignForm.set(key, value))
  }

  useEffect(() => {
    if (measurement) {
      if (
        measurement.subjects?.length < 1 ||
        measurement.assessors?.length < 1
      ) {
        setActiveView(MeasurmentViews.SETTINGS)
      }
    }
  }, [measurement])

  const [createNpsCampaign] = useMutation(CREATE_NPS_CAMPAIGN, {
    onCompleted: () => {
      toast.success('Campaign created', {
        duration: 2000,
      })
    },
    refetchQueries: ['FindNpsMeasurementQuery'],
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
        className: 'flex-column',
      })
    },
  })

  const createNewCampaign = async () => {
    if (parseInt(campaignForm.get('Surveys Sent')) >= 0) {
      await createNpsCampaign({
        variables: {
          input: {
            measurementId: measurement.id,
            campaignType: 'SUMMARY',
            name: campaignForm.get('Name'),
            campaignDate: selectedDate,
            surveySentCount: parseInt(campaignForm.get('Surveys Sent')),
          },
        },
        refetchQueries: ['NpsMeasurementsList', 'NpsCampaignList'],
      })

      campaignModal.toggle()
    } else if (campaignForm.get('Surveys Sent') === '') {
      await createNpsCampaign({
        variables: {
          input: {
            measurementId: measurement.id,
            campaignType: 'SUMMARY',
            name: campaignForm.get('Name'),
            campaignDate: selectedDate,
            surveySentCount: 0,
          },
        },
        refetchQueries: ['NpsMeasurementsList', 'NpsCampaignList'],
      })

      campaignModal.toggle()
    } else {
      toast.error('Surveys Sent must be a positive number', {
        duration: 2000,
      })
    }
  }

  const handleCreateCampaign = () => {
    setCampaignForm(
      new Map([
        ['Name', ''],
        ['Surveys Sent', '0'],
      ]),
    )
    campaignModal.toggle()
  }

  return (
    <div className={'flex flex-col w-3/4'}>
      <NavigationHeader
        activeView={activeView}
        setActiveView={setActiveView}
        createNewCampaign={handleCreateCampaign}
      />
      <Modal
        onClose={campaignModal.toggle}
        open={campaignModal.value}
        title={'Create Campaign'}
        dialogClassName={'!p-0 max-w-[720px]'}
        footerVisible
        onConfirm={() => {
          const nameInvalid = campaignForm.get('Name') === ''
          if (nameInvalid) {
            toast.error('Campaign name is required')
            return
          }
          createNewCampaign()
        }}
        confirmText={'Create'}
        onCancel={campaignModal.toggle}
      >
        <div className={'flex-col p-4 justify-center'}>
          <SimpleForm
            records={campaignForm}
            handleChange={updateCampaignForm}
          />
          <DatePicker
            className={'mt-4'}
            label="START DATE"
            openTo="day"
            views={['year', 'month', 'day']}
            value={selectedDate}
            onChange={(newValue) => {
              setSelectedDate(newValue)
            }}
            sx={{ width: '100%' }}
          />
        </div>
      </Modal>
      {
        {
          [MeasurmentViews.CAMPAIGNS]: (
            <NpsCampaignCell
              measurementId={measurement.id}
              subjects={subjects}
            />
          ),
          [MeasurmentViews.SETTINGS]: (
            <NpsSettingsView
              measurement={measurement}
              memberships={memberships}
              membershipGroups={membershipGroups}
              assessors={assessors}
            />
          ),
        }[activeView]
      }
    </div>
  )
}

export default NpsMeasurementView
