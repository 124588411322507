import { FC } from 'react'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'
import NpsSubjectsView from 'src/components/Nps/NpsSettings/NPSSubjectsView/NPSSubjectsView'

export const QUERY = gql`
  query NpsSubjectsQuery {
    subjects: npsSubjects {
      id
      name
      position
    }
    members: membershipsByClient {
      id
      user {
        name
        position
        avatarUrl
      }
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = ({ members, subjects }) => (
  <>
    <NpsSubjectsView members={members} npsSubjects={subjects} />
  </>
)

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success: FC<CellSuccessProps> = ({ subjects, members }) => {
  return <NpsSubjectsView members={members} npsSubjects={subjects} />
}
