export const CREATE_NPS_ASSESSOR = gql`
  mutation CreateNpsAssessor($input: CreateNpsAssessorInput!) {
    createNpsAssessor(input: $input) {
      id
    }
  }
`

export const UPDATE_NPS_ASSESSOR = gql`
  mutation UpdateNpsAssessor($id: Int!, $input: UpdateNpsAssessorInput!) {
    updateNpsAssessor(id: $id, input: $input) {
      id
    }
  }
`

export const DELETE_NPS_ASSESSOR = gql`
  mutation DeleteNpsAssessor($id: Int!) {
    deleteNpsAssessor(id: $id) {
      id
    }
  }
`
