export const CREATE_NPS_MEASUREMENT = gql`
  mutation CreateNpsMeasurement($input: CreateNpsMeasurementInput!) {
    createNpsMeasurement(input: $input) {
      id
    }
  }
`

export const UPDATE_NPS_MEASUREMENT = gql`
  mutation UpdateNpsMeasurement($id: Int!, $input: UpdateNpsMeasurementInput!) {
    updateNpsMeasurement(id: $id, input: $input) {
      id
    }
  }
`

export const DELETE_NPS_MEASUREMENT = gql`
  mutation DeleteNpsMeasurement($id: Int!) {
    deleteNpsMeasurement(id: $id) {
      id
    }
  }
`
